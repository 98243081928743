import React, { FunctionComponent, SVGAttributes } from "react";
import { SimpleGrid } from "@chakra-ui/core";
import QuestionsIcon from "@/assets/svg/foundations-questions.svg";
import EditorialsIcon from "@/assets/svg/foundations-editorial.svg";
import SolutionsIcon from "@/assets/svg/foundations-solutions.svg";
import ExecutionIcon from "@/assets/svg/foundations-code-execution.svg";
import SpaceTimeIcon from "@/assets/svg/foundations-space-time.svg";
import CheckmarkIcon from "@/assets/svg/checked.svg";
import styles from "@/styles/Home.module.css";

const features: Feature[] = [
  {
    icon: QuestionsIcon,
    title: "110 Hand-Picked Questions",
    description:
      "Coding interview prep is a numbers game that many candidates lose. We've hand-picked 110 of the best coding interview questions to truly prepare you.",
  },
  {
    icon: EditorialsIcon,
    title: "110 Editorials",
    description:
      "Algorithms are tough to learn on paper. Each and every one of our questions is accompanied by a editorial to maximize learning.",
  },
  {
    icon: SolutionsIcon,
    title: "Solutions In 4 Languages",
    description:
      "Not everyone speaks the same programming language. We give you solutions to every single question in 4 different languages: Python, C++, Java and TypeScript.",
  },
  {
    icon: ExecutionIcon,
    title: "Code-Execution Environment",
    description:
      "Coding out solutions to algorithm problems is the best way to practice. Our code-execution environment lets you type out your answers and run them against our test cases right here on the website.",
  },
  {
    icon: SpaceTimeIcon,
    title: "Space-Time Complexity Analysis",
    description:
      "Understanding how much memory an algorithm uses and how fast it runs is exceedingly important. All of our explanations include a rigorous space-time complexity analysis.",
  },
  {
    icon: CheckmarkIcon,
    title: "Certificate Of Completion",
    description:
      "Completing 110 questions on Interview AC is no easy feat. It takes time and effort, and it demonstrates a keen understanding of data structures and algorithms.",
  },
];

interface Feature {
  icon?: FunctionComponent<SVGAttributes<SVGElement>>;
  title: string;
  description: string;
}

const Features = () => {
  const getFeature = (feature: Feature, key: number) => {
    return (
      <div className={styles.feature} key={key}>
        {feature.icon && <feature.icon className={styles.featureIcon} />}
        <h4 className={styles.featureTitle}>{feature.title}</h4>
        <p className={styles.featureDesc}>{feature.description}</p>
      </div>
    );
  };

  return (
    <div className={styles.features}>
      <h1 className={styles.subheading}>What is Interview AC?</h1>
      <SimpleGrid
        columns={[1, 1, 2, 3]}
        gridColumnGap="3rem"
        gridRowGap="3rem"
        className={styles.featuresGrid}
      >
        {features.map((feature, i) => getFeature(feature, i))}
      </SimpleGrid>
    </div>
  );
};

export default Features;
