import React from "react";
import { SimpleGrid } from "@chakra-ui/core";
import styles from "@/styles/Home.module.css";

const AuthorInfo = () => {
  return (
    <div className={styles.features}>
      <h1 className={styles.subheading}>About the author</h1>
      <SimpleGrid
        columns={{ sm: 1, md: 1, lg: 2 }}
        gridColumnGap="1rem"
        gridRowGap="1rem"
        className={styles.authorGrid}
      >
        <div className={styles.authorText}>
          I'm Alex Wice, previously #2 Global Rating on LeetCode. Welcome to my
          Foundations Course here on Interview AC where I teach you the latest
          tricks and shit to help you ace your programming interview. In this
          course, we will cover array, strings, dynamic programming, and some
          other programming topics.
        </div>
        <div className={styles.authorImage}>
          <img
            src="/awice.jpg"
            alt="Alex Wice"
            className={styles.authorImage_img}
          />
        </div>
      </SimpleGrid>
    </div>
  );
};

export default AuthorInfo;
