import React from "react";
import {
  fetchCheckoutApi,
  StripeProduct,
} from "@/modules/thunks/api/fetchCheckout";
import getStripe from "@/utils/getStripe";
import CardIcon from "@/assets/svg/card.svg";
import styles from "@/styles/Home.module.css";

const CTA = () => {
  const redirectToCheckout = async () => {
    const stripe = await getStripe();
    const { id: sessionId } = await fetchCheckoutApi(
      StripeProduct.FoundationsCourse
    );

    const { error } = await stripe.redirectToCheckout({ sessionId });
  };

  return (
    <div className={styles.features}>
      <h1 className={styles.subheading}>
        Ready to ace your programming interview?
      </h1>
      <div>
        Enroll in the Foundations Course to prepare yourself to land your dream
        job!
      </div>
      <button className={styles.buyButton} onClick={redirectToCheckout}>
        <CardIcon className={styles.cardIcon} />
        Buy Foundations Course
      </button>
    </div>
  );
};

export default CTA;
