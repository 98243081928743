import Head from "next/head";
import dynamic from "next/dynamic";
import { UserSubmissionProvider } from "@/context/UserSubmissionContext";
import Header from "@/components/FoundationsCourse/Header";
import Features from "@/components/FoundationsCourse/Features";
import Topics from "@/components/FoundationsCourse/Topics";
import AuthorInfo from "@/components/FoundationsCourse/Author";
import CTA from "@/components/FoundationsCourse/CTA";
import Sidebar from "@/components/Landing/LandingSidebar";
import styles from "@/styles/Home.module.css";

export default function Home() {
  return (
    <UserSubmissionProvider>
      <div className={styles.container}>
        <Head>
          <title>Interview AC</title>
        </Head>
        <Sidebar />
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <Header />
            <Features />
            <Topics />
            <AuthorInfo />
            <CTA />
          </div>
        </div>
      </div>
    </UserSubmissionProvider>
  );
}
