import React from "react";
import { Icon, SimpleGrid } from "@chakra-ui/core";
import styles from "@/styles/Home.module.css";

const topics = [
  "Binary Search Trees",
  "Binary Trees",
  "Linked Lists",
  "Searching",
  "Strings",
  "Arrays",
  "Stacks",
  "Dynamic Programming",
  "Famous Algorithms",
  "Recursion",
  "Sorting",
  "Graphs",
  "Heaps",
  "Tries",
];

const Topics = () => {
  return (
    <div className={styles.features}>
      <h1 className={styles.subheading}>What does the course cover?</h1>
      <SimpleGrid columns={[1, 2]} className={styles.topicsGrid}>
        {topics.map((topic, i) => (
          <div key={i}>
            <Icon name="check" mr={4} />
            {topic}
          </div>
        ))}
      </SimpleGrid>
    </div>
  );
};

export default Topics;
