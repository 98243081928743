import React, { FC } from "react";
import Link from "next/link";
import { UrlObject } from "url";
import { Flex } from "@chakra-ui/core";
import IACLogo from "@/assets/svg/logo.svg";
import ProblemListIcon from "@/assets/svg/sidebar-list.svg";
import CourseIcon from "@/assets/svg/sidebar-course.svg";
import AboutIcon from "@/assets/svg/sidebar-info.svg";
import DiscordIcon from "@/assets/svg/sidebar-discord.svg";
import YouTubeIcon from "@/assets/svg/sidebar-youtube.svg";
import styles from "@/styles/Sidebar.module.css";

const SidebarLink: FC<{ href: string | UrlObject }> = ({ href, children }) => {
  return (
    <Link href={href} passHref>
      <a>{children}</a>
    </Link>
  );
};

const Sidebar = () => {
  return (
    <div className={styles.sidebar}>
      <Flex align="center" justify="center">
        <SidebarLink href="/">
          <IACLogo className={styles.sidebarLogo} />
        </SidebarLink>
      </Flex>
      <div className={styles.sidebarContent}>
        <div className={styles.top}>
          <SidebarLink href="/problems">
            <div className={styles.sidebarItem}>
              <ProblemListIcon className={styles.sidebarIcon} />
              Problem List
            </div>
          </SidebarLink>
          <SidebarLink href="/foundations-course">
            <div className={styles.sidebarItem}>
              <CourseIcon className={styles.sidebarIcon} />
              Foundations Course
            </div>
          </SidebarLink>
          <SidebarLink href="/mastery-course">
            <div className={styles.sidebarItem}>
              <CourseIcon className={styles.sidebarIcon} />
              Mastery Course
            </div>
          </SidebarLink>
          <SidebarLink href="/about">
            <div className={styles.sidebarItem}>
              <AboutIcon className={styles.sidebarIcon} />
              About
            </div>
          </SidebarLink>
        </div>
        <div className={styles.bottom}>
          <SidebarLink href="https://www.youtube.com/alexwice">
            <div className={styles.sidebarItem}>
              <YouTubeIcon className={styles.sidebarIcon} />
              interview.weekly
            </div>
          </SidebarLink>
          <SidebarLink href="http://interview.solutions">
            <div className={styles.sidebarItem}>
              <DiscordIcon className={styles.sidebarIcon} />
              interview.solutions
            </div>
          </SidebarLink>
          <div className={styles.sidebarFooter}>
            <div className={styles.smallCopyright}>
              &copy; 2021 Interview Solutions
            </div>
            <SidebarLink href="/privacy">
              <div className={styles.smallLink}>Privacy</div>
            </SidebarLink>
            <SidebarLink href="/terms">
              <div className={styles.smallLink}>Terms</div>
            </SidebarLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
