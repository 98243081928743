import { axiosInstance } from '@/utils/fetch'

enum StripeProduct {
  FoundationsCourse = 'foundations-course',
  MasteryCourse = 'mastery-course',
}

const STRIPE_BUY = '/stripe/buy'

const fetchCheckout = async (product: StripeProduct) => {
  const res = await axiosInstance().get(`${STRIPE_BUY}/${product}`)
  return res.data
}

export { STRIPE_BUY, StripeProduct, fetchCheckout as fetchCheckoutApi }
