import React from "react";
import { Grid } from "@chakra-ui/core";
import CardIcon from "@/assets/svg/card.svg";
import styles from "@/styles/Home.module.css";

const Header = () => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" className={styles.headerGrid}>
      <div>
        <div className={styles.header}>
          <h1 className={styles.heading}>Foundations Course</h1>
          <p>
            The ultimate resource to prepare for coding interviews. Everything
            you need, in one streamlined platform.
          </p>
          <button className={styles.buyButton}>
            <CardIcon className={styles.cardIcon} />
            Buy Foundations Course
          </button>
        </div>
      </div>
      <div className="bg-green-100 hidden md:block"></div>
    </Grid>
  );
};

export default Header;
